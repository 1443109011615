import { Button } from "antd";
import { Layout } from "../Layout/component";
import { LoyoutItem } from "../LayoutItem/component";
import styles from "./styles/styles.module.css";
import { useEffect, useRef, useState } from "react";
import { FormAddCase } from "../FormAddCase/component";

export const AddCase = () => {
  const [start, setStart] = useState();

  const stepOne = useRef();

  useEffect(() => {
    if (start) {
      stepOne.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [start]);

  return (
    <div className={styles.add_case_page}>
      <Layout>
        <LoyoutItem>
          <div className={styles.block}>
            <div className={styles.content}>
              <h1>
                Создавайте кейсы и получаейте лучший мэтч со специалистами
              </h1>
              <div className={styles.info}>
                Все очень просто, заполните небольшую форму и мы будем знать о
                Вас чуточку больше. Это займет не более 5 минут. Опишите кейс
              </div>
              <div className={styles.info}>
                Равным образом дальнейшее развитие различных форм деятельности
                представляет собой интересный эксперимент проверки модели
                развития. Таким образом реализация намеченных плановых заданий
                способствует подготовки и реализации систем массового участия.
              </div>

              <Button icon={"⚡"} size="large" onClick={() => setStart(!start)}>
                Заполнить кейс
              </Button>
            </div>

            <div className={styles.image} alt="" />
          </div>
        </LoyoutItem>

        {start && (
          <LoyoutItem>
            <div ref={stepOne} style={{ marginTop: "150px", paddingTop: '100px' }}>
              <FormAddCase />
            </div>
          </LoyoutItem>
        )}
      </Layout>
    </div>
  );
};
