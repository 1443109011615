import { Button, Form, Input, InputNumber } from "antd";
import { ErrorMessage } from "./ErrorMessage";
import { instance } from "../../axios";
import { useState } from "react";
import { FormSendCode } from "./FormSendCode";

export const FormForgotPassword = ({
  apiEmail,
  apiPhone,
  setUserIsRegistration,
}) => {
  const [isValidEmailOrPhone, setIsValidEmailOrPhone] = useState(null);

  const [textError, setTextError] = useState(null);
  const [showEnterCode, setShowEnterSode] = useState({
    show: false,
    type: null,
    field: null,
  });

  const onSendInfoUser = (values) => {
    if (values.email) {
      const stucture = {
        email: values.email,
      };

      instance
        .post(apiEmail, {
          email: stucture.email,
        })
        .then((response) => {
          console.log("response", response);

          if (response.status === 201) {
            setShowEnterSode({
              show: true,
              type: "email",
              field: stucture.email,
            });
          }
        })
        .catch((error) => {
          console.log(error);

          // if (error.response.status === 409) {
          // 	console.log(409);
          // 	setTextError("Пользователь с таким email уже существует");
          // }
        });
    }

    if (values.telephone) {
      const phone = `+7${values.telephone}`;

      instance
        .post(apiPhone, {
          phone: phone,
        })
        .then((response) => {
          console.log(response);

          if (response.status === 201) {
            setShowEnterSode({
              show: true,
              type: "phone",
              field: phone,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            // setTextError(
            // 	"Не верный номер телефона или пароль"
            // );
          }
          if (error.response.status === 409) {
            // setTextError(
            // 	"Пользователь с таким номером телефона уже существует"
            // );
          }
        });
    }
  };

  const onSendFailedInfoUser = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setIsValidEmailOrPhone(true);
  };

  return (
    <>
      {showEnterCode.show ? (
        <FormSendCode
          showEnterCode={showEnterCode}
          setUserIsRegistration={setUserIsRegistration}
        />
      ) : (
        <>
          <div style={{ marginBottom: "1rem" }}>
            Пожалуйста, введите email или номер телефона, чтобы восстановить
            доступ к аккаунту.
          </div>
          <Form
            layout="vertical"
            onFinish={onSendInfoUser}
            onValuesChange={() => {
              setTextError("");
              setIsValidEmailOrPhone(false);
            }}
            onFinishFailed={onSendFailedInfoUser}
          >
            <Form.Item
              name={["email"]}
              label="E-mail"
              rules={[
                {
                  type: "email",
                  required: isValidEmailOrPhone,
                  message: `Введите корректный email`,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={["telephone"]}
              label="Телефон"
              rules={[
                {
                  type: "telephone",
                  required: isValidEmailOrPhone,
                  message: `Введите корректный телефон`,
                },
              ]}
            >
              <InputNumber
                controls={false}
                addonBefore={"+7"}
                style={{
                  width: "100%",
                }}
                maxLength={10}
              />
            </Form.Item>

            {textError && (
              <div style={{ width: "100%", marginTop: "1rem" }}>
                <ErrorMessage>{textError}</ErrorMessage>
              </div>
            )}

            <Button
              style={{ width: "100%", marginTop: "1rem" }}
              type="primary"
              htmlType="submit"
            >
              Получить код доступа
            </Button>
          </Form>
        </>
      )}
    </>
  );
};
