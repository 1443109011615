import { Button, Input, Tooltip } from "antd";
import { useState } from "react";
import { IoIosAddCircleOutline, IoIosClose } from "react-icons/io";
import { ErrorMessage } from "../Registration/ErrorMessage";

export const Tags = () => {
  const [tags, setTags] = useState([]);
  const [inputTag, setInputTag] = useState("");

  const [message, setMessage] = useState(false);

  const onChange = (e) => {
    const { value } = e.target;
    setInputTag(value);
    setMessage(false);
  };

  const addTag = () => {
    if (tags.includes(inputTag)) return setMessage(true);
    return setTags([...tags, inputTag]);
  };

  const deleteTag = (deleteTag) => {
    const filterTag = tags.filter((tag) => tag !== deleteTag);
    setMessage(false);

    return setTags(filterTag);
  };

  return (
    <>
      {tags.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          {tags.map((tag) => {
            return (
              <div
                key={tag}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "1rem",
                }}
              >
                {tag}

                <IoIosClose
                  style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                  onClick={() => deleteTag(tag)}
                />
              </div>
            );
          })}
        </div>
      )}

      <div style={{ display: "flex", alignItems: "center" }}>
        <Input placeholder={"Введите тег"} onChange={onChange} size="large" />
        <Tooltip title="Добавить тег" placement="bottom">
          <Button
            onClick={addTag}
            type="text"
            size="large"
            style={{
              marginLeft: "0.5rem",
              fontSize: " 1.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IoIosAddCircleOutline onClick={addTag} />
          </Button>
        </Tooltip>
      </div>

      <div style={{ marginTop: "1rem" }}>
        {message && <ErrorMessage>Такой тег уже существует</ErrorMessage>}
      </div>
    </>
  );
};
