import { Feature } from "../Feature/component";
import { Cases } from "../Cases/component";
import { FilterCases } from "../FilterCases/component";
import { SelectedCategories } from "../SelectedCategories/component";
import { Layout } from "../Layout/component";
import { Main } from "../Main/Main";
import { LoyoutItem } from "../LayoutItem/component";

export const HomePage = () => {
  return (
    <>
      <Main />

      <Layout>
        <LoyoutItem>
          <Feature />
        </LoyoutItem>

        <LoyoutItem>
          <SelectedCategories />
        </LoyoutItem>

        <LoyoutItem>
          <FilterCases />
        </LoyoutItem>

        <LoyoutItem>
          <Cases />
        </LoyoutItem>
      </Layout>
    </>
  );
};
