import axios from "axios";

export const instance = axios.create({
  baseURL: "http://localhost:3000",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  proxy: {
    protocol: "https",
    host: "https://persona.bz",
  },
});

instance.defaults.headers.common["Authorization"] = "";
