import classNames from "classnames";
import styles from "./styles/styles.module.css";
import { Link } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { FaChevronRight } from "react-icons/fa";
import { IoHomeOutline } from "react-icons/io5";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const BreadCrumbs = ({ showMiniHeader }) => {
  const breadcrumbs = useBreadcrumbs();

  console.log(breadcrumbs[0], breadcrumbs[1]);

  if (
    breadcrumbs.filter(
      (crumb) =>
        crumb.key === "/email-activation" || crumb.key === "/reset-password"
    ).length > 0
  ) {
    return (
      <div className={styles.spiner}>
        <div>
          Ожидаем активацию аккаунта
          <br />
          <br /> Подождите
        </div>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 24,
              }}
              spin
            />
          }
        />
      </div>
    );
  }

  return (
    breadcrumbs[1] && (
      <div
        className={classNames(
          styles.bread_crumbs,
          showMiniHeader && styles.bread_crumbs_top
        )}
      >
        {breadcrumbs.map(({ breadcrumb, match }, index) => (
          <div key={match.pathname} onClick={console.log(match)}>
            <Link
              to={match.pathname}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className={styles.bread_crumbs_item}>
                {breadcrumb.props.children === "Home" ? (
                  <div className={styles.home}>
                    <IoHomeOutline />
                  </div>
                ) : (
                  breadcrumb
                )}
              </div>
              <div className={styles.chevron}>
                {index < breadcrumbs.length - 1 && <FaChevronRight />}
              </div>
            </Link>
          </div>
        ))}
      </div>
    )
  );
};
