import { Cases } from "../Cases/component";
import { ContentCaseMain } from "../ContentCaseMain/component";
import { Layout } from "../Layout/component";
import { LoyoutItem } from "../LayoutItem/component";
import SliderFor from "../SliderFor/component";
import styles from "./styles/styles.module.css";

export const ContentCase = () => {
  return (
    <>
      <div className={styles.page}>
        <ContentCaseMain styles={styles} />
        <Layout>
          <LoyoutItem>
            <div className={styles.sliders}>
              <SliderFor />
            </div>
          </LoyoutItem>
          <div className={styles.similar}>Читать так же</div>
          <LoyoutItem>
            <Cases />
          </LoyoutItem>
        </Layout>
      </div>
    </>
  );
};
