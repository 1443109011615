import { Tabs } from "antd";

export const TabsSteps = ({ caseIsAvalible, roleIsAvalible }) => {
  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          label: "Заполнение профиля",
          key: "1",
        },
        {
          label: "Заполнение кейса",
          key: "2",
          disabled: caseIsAvalible,
        },
        {
          label: "Заполнение роли",
          key: "3",
          disabled: roleIsAvalible,
        },
      ]}
    />
  );
};
