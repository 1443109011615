import classNames from "classnames";
import { useEffect, useState } from "react";
import { FaRegSquareCheck } from "react-icons/fa6";
import { ImCheckboxUnchecked } from "react-icons/im";
import image from "./img/ai.svg";

export const Categorie = ({
  categorie,
  styles,
  onCheckedItem,
  onUnCheckedItem,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!onCheckedItem && !onUnCheckedItem) return;

    if (checked) {
      onCheckedItem(categorie.name);
    } else {
      onUnCheckedItem(categorie.name);
    }
  }, [categorie.name, checked, onCheckedItem, onUnCheckedItem]);

  return (
    <div
      className={classNames(styles.item, checked && styles.checked)}
      onClick={() => {
        setChecked(!checked);
      }}
    >
      <div className={styles.checkbox}>
        {checked ? <FaRegSquareCheck /> : <ImCheckboxUnchecked />}
      </div>
      {categorie.image &&
        (!categorie.libraryImage ? (
          <img src={image} alt="sd" height={"100%"} width={"100%"} />
        ) : (
          categorie.image
        ))}
      <div>{categorie.name}</div>
    </div>
  );
};
