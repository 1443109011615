import { FaLinkedin } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

export const SocialIcons = ({ styles }) => {
  const socialLogin = (provider) => {
    const params =
      "width=1000,height=650,menubar=no,location=yes,resizable=no,scrollbars=no,status=no";
    window.open(`http://persona.bz/api/auth/${provider}`, null, params);
  };

  const socialLoginItems = [
    {
      icon: <FaLinkedin color="0e76a8" className={styles.icon} />,
      provider: "linkedin",
    },
    {
      icon: <FaGoogle color="#4285F4" className={styles.icon} />,
      provider: "google",
    },
    {
      icon: <FaGithub color="black" className={styles.icon} />,
      provider: "github",
    },
    {
      icon: <FaApple color="black" className={styles.icon} />,
      provider: "apple",
    },
    {
      icon: <FaFacebook color="#4267B2" className={styles.icon} />,
      provider: "facebook",
    },
  ];

  return (
    <div className={styles.social_login}>
      {socialLoginItems.map((item) => {
        return (
          <button
            className={styles.social_item}
            onClick={() => socialLogin(item.provider)}
          >
            {item.icon}
          </button>
        );
      })}
    </div>
  );
};
