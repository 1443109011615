import { Select } from "antd";

const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}

export const MultySelect = ({ current }) => {
  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };

  return (
    <Select
      mode="tags"
      size={"large"}
      placeholder={current.title}
      defaultValue={["a10", "c12"]}
      onChange={handleChange}
      style={{
        width: "100%",
      }}
      options={options}
    />
  );
};
