import { Steps } from "antd";

export const StepsContent = ({ onChange, current, steps }) => {
  console.log("steps", steps);
  return (
    <div style={{ width: "15rem", paddingTop: "1rem" }}>
      <Steps
        current={current}
        onChange={onChange}
        direction="vertical"
        size="large"
        items={steps}
      />
    </div>
  );
};
