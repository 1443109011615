import { useState } from "react";
import { Button, Form, InputNumber } from "antd";
import { instance } from "../../axios";

export const FormSendCode = ({ showEnterCode, setUserIsRegistration }) => {
  const { textError } = useState("");

  const onSendCode = (values) => {
    console.log("Success:", values);

    if (showEnterCode.type === "phone") {
      instance
        .post("https://persona.bz/api/auth/activate-phone", {
          phone: showEnterCode.field,
          code: values.code,
        })
        .then((response) => {
          console.log("response", response);
          if (response.status === 201) {
            setUserIsRegistration(true);
            localStorage.setItem(
              "tokenEnterPersona",
              response.data.data.tokens.accessToken
            );
          }
        })

        .catch((error) => {
          console.log(error);
        });
    }

    if (showEnterCode.type === "email") {
      instance
        .get(
          `https://persona.bz/api/auth/activate-email/${showEnterCode.field}/${values}`,
          {
            phone: showEnterCode.field,
            code: values.code,
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.status === 201) {
            setUserIsRegistration(true);
            localStorage.setItem(
              "tokenEnterPersona",
              response.data.data.tokens.accessToken
            );
          }
        })

        .catch((error) => {
          console.log(error);
        });
    }
  };
  console.log(showEnterCode);
  return (
    <>
      <div style={{ marginBottom: "1rem" }}>
        {showEnterCode.type === "email" ? (
          <span>
            На указанную почту - <b>{showEnterCode.field}</b>, Мы отправили Вам
            письмо с ссылкой для активации аккаунта.
            <br />
            <br />
            Пожалуйста, перейдите в почту и активируйте аккаунт
          </span>
        ) : (
          "Пожалуйста, введите код подтверждения, который мы отправили вам сообщением на телефон"
        )}
        .
      </div>

      {showEnterCode.type !== "email" && (
        <Form layout="vertical" onFinish={onSendCode}>
          <Form.Item
            label="Одноразовый код"
            name="code"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите код из смс или почты",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              maxLength={4}
              controls={false}
            />
          </Form.Item>

          {textError?.length > 0 && (
            <div style={{ width: "100%", marginTop: "1rem" }} color="red">
              {textError}
            </div>
          )}

          <Button
            style={{ width: "100%", marginTop: "1rem" }}
            type="primary"
            htmlType="submit"
          >
            Отправить код
          </Button>
        </Form>
      )}
    </>
  );
};
