import styles from "./styles/styles.module.css";
import { Categorie } from "../Categorie/component";

export const Categories = ({ items, onCheckedItem, onUnCheckedItem }) => {
  return (
    <div className={styles.items}>
      {items
        .filter((item) => item.visible)
        .map((item) => {
          return (
            <Categorie
              key={item.name}
              categorie={item}
              styles={styles}
              onUnCheckedItem={onUnCheckedItem}
              onCheckedItem={onCheckedItem}
            />
          );
        })}
    </div>
  );
};
