import { useState } from "react";
import { Switch } from "../Switch/component";
import { Categories } from "../Categories/component";
import { ButtonComponent } from "../Button/component";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import styles from "./styles/styles.module.css";
import { Link } from "react-router-dom";

export const SelectedCategories = () => {
  const [selectCategories, setSelectCategories] = useState("command");

  const items = [
    {
      name: "Mobile App",
      type: "command",
      visible: selectCategories === "command",
      image: "manager",
    },
    {
      name: "Web App",
      type: "command",
      visible: selectCategories === "command",
      image: "manager",
    },
    {
      name: "Data Science",
      type: "command",
      visible: selectCategories === "command",
      image: "manager",
    },
    {
      name: "Gen AI Feature",
      type: "command",
      visible: selectCategories === "command",
      image: "manager",
    },
    {
      name: "Rapid Prototype",
      type: "command",
      visible: selectCategories === "command",
      image: "manager",
    },
    {
      name: "Marketing Website",
      type: "command",
      visible: selectCategories === "command",
      image: "manager",
    },
    {
      name: "Technology Migration",
      type: "command",
      visible: selectCategories === "command",
      image: "manager",
    },
    {
      name: "System Architecture",
      type: "command",
      visible: selectCategories === "command",
      image: "manager",
    },

    {
      name: "Front End Developer",
      type: "specialist",
      visible: selectCategories === "specialist",
      image: "frontend",
    },
    {
      name: "Product Manager",
      type: "specialist",
      visible: selectCategories === "specialist",
      image: "manager",
    },
    {
      name: "Product Designer",
      type: "specialist",
      visible: selectCategories === "specialist",
      image: "product",
    },
    {
      name: "Data Scientist",
      type: "specialist",
      visible: selectCategories === "specialist",
      image: "data",
    },
    {
      name: "Growth Marketer",
      type: "specialist",
      visible: selectCategories === "specialist",
      image: "marketing",
    },
    {
      name: "AI Expert",
      type: "specialist",
      visible: selectCategories === "specialist",
      image: "ai",
    },
    {
      name: "Back End Developer",
      type: "specialist",
      visible: selectCategories === "specialist",
      image: "backend",
    },
    {
      name: "CXO",
      type: "specialist",
      visible: selectCategories === "specialist",
      image: "CXO",
    },
  ];

  return (
    <div className={styles.selected_categories}>
      <Switch
        setSelectCategories={setSelectCategories}
        selectCategories={selectCategories}
      />
      <Categories selectCategories={selectCategories} items={items} />
      <div className={styles.start}>
        <Link to={"/addcase"}>
          <ButtonComponent
            text="начать"
            icon={<IoArrowForwardCircleOutline />}
          />
        </Link>
      </div>
    </div>
  );
};
