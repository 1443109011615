import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { MultySelect } from "../MultySelect/MultySelect";

export const SelectedRole = ({ current }) => {
  const [showMoreRole, setShowMoreRole] = useState(true);

  return (
    <>
      <MultySelect current={current} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Дополниетльные роли</h4>
        {showMoreRole && (
          <AiOutlineClose
            onClick={() => {
              setShowMoreRole(false);
            }}
          />
        )}
      </div>

      {showMoreRole ? (
        <MultySelect current={current} />
      ) : (
        <div>Дополнительные роли не выбраны</div>
      )}
    </>
  );
};
