import { Component } from "react";
import Slider from "scroll-carousel-react";
import styles from "./styles/styles.module.css";
import image from "./background-image/Group8.png";
import image2 from "./background-image/image19.png";

const items = [
  {
    id: 1,
    title: "Для стартапов",
    src: image,
  },
  { id: 2, title: " Для SMB", src: image2 },
  { id: 3, title: "Для корпораций", src: image },
];

export default class SliderFor extends Component {
  render() {
    const settings = {
      autoplay: this.props.autoplay ? true : false,
    };

    return (
      <Slider {...settings} className={styles.slider}>
        {items.map((item) => {
          return (
            <div key={item.id} className={styles.carusel_item}>
              <div className={styles.title}>{item.title}</div>
              <div
                className={styles.background}
                style={{
                  backgroundImage: `url(${item.src})`,
                }}
              />
            </div>
          );
        })}
      </Slider>
    );
  }
}
