import { YMaps, Map } from "@pbe/react-yandex-maps";
import { Input, Spin } from "antd";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

export const Location = () => {
  const [nav, setNav] = useState({
    latitude: null,
    longitude: null,
    city: null,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log("Latitude:", position.coords.latitude);
        console.log("Longitude:", position.coords.longitude);

        setNav({
          city: navigator.geolocation.city,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      function (error) {
        console.error("Ошибка получения местоположения:", error);
      }
    );
  }, []);

  if (nav.latitude === null) {
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 24,
          }}
          spin
        />
      }
    />;
  }

  return (
    <>
      <Input
        placeholder="Ваша локация"
        allowClear
        value={nav.city}
        // prefix={icon}
        maxLength={20}
        size="large"
        // onChange={}
      />
      <YMaps>
        <Map
          defaultState={{ center: [nav.latitude, nav.longitude], zoom: 9 }}
        />
      </YMaps>
    </>
  );
};
