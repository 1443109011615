import { Button, Form, Input, InputNumber } from "antd";
import { ErrorMessage } from "./ErrorMessage";
import { instance } from "../../axios";
import { useState } from "react";

export const MainForm = ({
  setShowEnterSode,
  apiEmail,
  apiPhone,
  labelButton,
  setUserIsRegistration,
}) => {
  const [isValidEmailOrPhone, setIsValidEmailOrPhone] = useState(null);
  const [textError, setTextError] = useState(null);

  const onSendInfoUser = (values) => {
    if (values.email) {
      const stucture = {
        email: values.email,
        password: values.password,
      };

      instance
        .post(apiEmail, {
          email: stucture.email,
          password: stucture.password,
        })
        .then((response) => {
          console.log("response", response);

          if (response.status === 201) {
            if (setShowEnterSode) {
              return setShowEnterSode({
                show: true,
                type: "email",
                field: stucture.email,
              });
            }

            if (setUserIsRegistration) {
              debugger;
              setUserIsRegistration(true);
              localStorage.setItem(
                "tokenEnterPersona",
                response.data.data.tokens.accessToken
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);

          if (error.response.status === 409) {
            console.log(409);
            setTextError("Пользователь с таким email уже существует");
          }

          if (error.response.status === 401) {
            console.log(409);
            setTextError("Неверный email или пароль");
          }
        });
    }

    if (values.telephone) {
      const phone = `+7${values.telephone}`;
      const password = values.password;

      instance
        .post(apiPhone, {
          phone: phone,
          password: password,
        })
        .then((response) => {
          console.log(response);

          if (response.status === 201) {
            if (setShowEnterSode) {
              return setShowEnterSode({
                show: true,
                type: "phone",
                field: phone.email,
              });
            }

            if (setUserIsRegistration) {
              debugger;
              setUserIsRegistration(true);
              localStorage.setItem(
                "tokenEnterPersona",
                response.data.data.tokens.accessToken
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            setTextError("Не верный номер телефона или пароль");
          }
          if (error.response.status === 409) {
            setTextError(
              "Пользователь с таким номером телефона уже существует"
            );
          }
        });
    }
  };

  const onSendFailedInfoUser = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setIsValidEmailOrPhone(true);
  };

  return (
    <>
      <div style={{ marginBottom: "1rem" }}>
        Пожалуйста, введите пароль, email или номер телефона, чтобы войти в
        аккаунт.
      </div>
      <Form
        layout="vertical"
        onFinish={onSendInfoUser}
        onValuesChange={() => {
          setTextError("");
          setIsValidEmailOrPhone(false);
        }}
        onFinishFailed={onSendFailedInfoUser}
      >
        <Form.Item
          name={["email"]}
          label="E-mail"
          rules={[
            {
              type: "email",
              required: isValidEmailOrPhone,
              message: `Введите корректный email`,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={["telephone"]}
          label="Телефон"
          rules={[
            {
              type: "telephone",
              required: isValidEmailOrPhone,
              message: `Введите корректный телефон`,
            },
          ]}
        >
          <InputNumber
            controls={false}
            addonBefore={"+7"}
            style={{
              width: "100%",
            }}
            maxLength={10}
          />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          required
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите пароль",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        {textError && (
          <div style={{ width: "100%", marginTop: "1rem" }}>
            <ErrorMessage>{textError}</ErrorMessage>
          </div>
        )}

        <Button
          style={{ width: "100%", marginTop: "1rem" }}
          type="primary"
          htmlType="submit"
        >
          {labelButton}
        </Button>
      </Form>
    </>
  );
};
