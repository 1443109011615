import { useState } from "react";
import { UserNoRegistry } from "./UserNoRegistry";
import { FormSendCode } from "./FormSendCode";
import { SocialIcons } from "./SocialIcons/SocialIcons";
import { Button } from "antd";
import { UserHaveAccount } from "./UserHaveAccount";
import { MdKeyboardArrowLeft } from "react-icons/md";

export const FormRegistration = ({ styles, setUserIsRegistration }) => {
  const [showEnterCode, setShowEnterSode] = useState({
    show: false,
    type: null,
    field: null,
  });

  const [isUserAccount, setIsuserAccount] = useState(false);

  console.log("showEnterCode", showEnterCode);

  return (
    <>
      {isUserAccount ? (
        <UserHaveAccount
          setIsuserAccount={setIsuserAccount}
          setUserIsRegistration={setUserIsRegistration}
        />
      ) : (
        <>
          {showEnterCode.show ? (
            <>
              <h2 style={{ marginTop: "2rem" }}>Вход</h2>
              <Button
                style={{
                  position: "absolute",
                  padding: 0,
                  display: "flex",
                  alignItems: "center",
                  top: "1rem",
                  left: "1rem",
                }}
                onClick={() =>
                  setShowEnterSode({ ...showEnterCode, show: false })
                }
                type="text"
              >
                <MdKeyboardArrowLeft fontSize={"1.5rem"} />
              </Button>
              <FormSendCode
                showEnterCode={showEnterCode}
                setUserIsRegistration={setUserIsRegistration}
              />
            </>
          ) : (
            <>
              <h2 style={{ marginTop: "2rem" }}>Вход | Регистрация</h2>
              <UserNoRegistry setShowEnterSode={setShowEnterSode} />
              <div className={styles.enter}>
                Уже есть аккаунт?{" "}
                <Button
                  type="primary"
                  size="small"
                  onClick={() => setIsuserAccount(true)}
                >
                  Войти
                </Button>
              </div>
              <SocialIcons styles={styles} />
            </>
          )}
        </>
      )}
    </>
  );
};
