import React, { useState } from "react";
import { Header } from "../Header/Header";
import { MiniHeader } from "../MiniHeader/component";
import classNames from "classnames";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "../HomePage/component";
import { PageCase } from "../PageCase/component";
import { BreadCrumbs } from "../BreadCrumbs/component";
import { AddCase } from "../AddCase/component";
import { Profile } from "../Profile/Profile";

export const App = () => {
  const [showMiniHeader, setMiniHeader] = useState(true);

  return (
    <>
      <BrowserRouter>
        <Header showMiniHeader={showMiniHeader}>
          <MiniHeader
            showMiniHeader={showMiniHeader}
            setMiniHeader={setMiniHeader}
          />
        </Header>
        <div className={classNames("wrapper", showMiniHeader && "wrapper_top")}>
          <BreadCrumbs showMiniHeader={showMiniHeader} />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/addcase" element={<AddCase />} />
            <Route path="/case" element={<PageCase />}>
              <Route
                path=":caseId"
                element={<PageCase />}
                preventScrollReset={true}
              />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
};
