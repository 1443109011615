import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { MdNavigateNext } from "react-icons/md";
import { ProfileLinks } from "../ProfileLinks/ProfileLinks";
import { Location } from "../Location/Location";
import { steps as stepsData } from "./steps";
import { UploaderFiles } from "../ProfileLinks/UploaderFiles";
import { FormatWork } from "../FormatWork/FormatWork";
import { MultySelect } from "../MultySelect/MultySelect";
import { OneSelect } from "../OneSelect/OneSelect";
import { SelectedRole } from "../SelectedRole/SelectedRole";
import { Tags } from "../Tags/Tags";
import { TabsSteps } from "./TabsSteps";
import { StepsContent } from "./StepsContent";

const { TextArea } = Input;

export const FormAddCase = () => {
  const [current, setCurrent] = useState(0);

  const [steps, setSteps] = useState();

  useEffect(() => {
    setSteps(stepsData);
  }, []);

  const [caseIsAvalible] = useState(true);
  const [roleIsAvalible] = useState(true);

  const onChange = (value) => {
    setCurrent(value);
  };

  return (
    steps !== undefined && (
      <div>
        <h2>
          {steps[current]?.typeStep === "case"
            ? "Заполнение кейса"
            : "Заполнение профиля"}
        </h2>

        <TabsSteps
          caseIsAvalible={caseIsAvalible}
          roleIsAvalible={roleIsAvalible}
        />

        <div style={{ display: "flex", marginTop: "3rem" }}>
          <div style={{ display: "flex" }}>
            <StepsContent onChange={onChange} current={current} steps={steps} />

            <div style={{ width: "30rem", marginLeft: "5rem" }}>
              {steps[current]?.type === "next_step" && (
                <div style={{ marginBottom: "2rem" }}>
                  🤩 Ура!!! Мы заполнили вашу анкету необходимыми данными.
                  Теперь, чтобы разместить кейс, нужно немного заполнить
                  информации о кейса
                </div>
              )}
              <h3>{steps[current]?.title}</h3>
              <div style={{ marginBottom: "1rem" }}>{steps[current]?.info}</div>
              <div style={{ width: "100%" }}>
                {steps[current]?.type === "format_work" && <FormatWork />}

                {steps[current]?.type === "multy_select" && (
                  <MultySelect current={steps[current]} />
                )}

                {steps[current]?.type === "select" && (
                  <OneSelect current={steps[current]} />
                )}

                {steps[current]?.type === "role_select" && (
                  <SelectedRole current={steps[current]} />
                )}

                {steps[current]?.type === "lists" && <ProfileLinks />}

                {steps[current]?.type === "location" && <Location />}

                {steps[current]?.type === "input" && (
                  <Input placeholder={steps[current].placeholder} />
                )}

                {steps[current]?.type === "text_aria" && (
                  <TextArea
                    rows={4}
                    placeholder="не более 200 слов"
                    maxLength={200}
                  />
                )}

                {steps[current]?.type === "upload" && <UploaderFiles />}

                {steps[current]?.type === "tags" && <Tags />}
              </div>

              <Button
                size="large"
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  alignItems: "center",
                }}
                type="primary"
                onClick={() => {
                  if (current === 5) {
                    onChange(5);
                  }
                  setCurrent(current + 1);
                }}
              >
                <div>Далее</div>
                <div style={{ marginTop: "3px", marginLeft: "1rem" }}>
                  <MdNavigateNext />
                </div>
              </Button>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "wheat",
              borderRadius: "1rem",
              width: "100%",
              height: "40rem",
              marginLeft: "10rem",
            }}
            alt=""
          />
        </div>
      </div>
    )
  );
};
