import { MainForm } from "./MainForm";

export const UserNoRegistry = ({ setShowEnterSode }) => {
  const apiEmail = "https://persona.bz/api/auth/register-by-email";
  const apiPhone = "https://persona.bz/api/auth/register-by-phone";

  return (
    <MainForm
      apiEmail={apiEmail}
      labelButton="Зарегистрироваться"
      apiPhone={apiPhone}
      setShowEnterSode={setShowEnterSode}
    />
  );
};
