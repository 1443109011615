import { useEffect, useState } from "react";
import { Modal, Tooltip } from "antd";
import classNames from "classnames";
import { CiUser } from "react-icons/ci";
import styles from "./styles/styles.module.css";
import { FormRegistration } from "./FormRegistration";
import { Link } from "react-router-dom";

export const Registration = ({
  userIsRegistration,
  setUserIsRegistration,
  onCloseMenu,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (userIsRegistration) {
      return setModalOpen(false);
    }
  }, [userIsRegistration]);

  useEffect(() => {
    window.addEventListener("message", ({ data }) => {
      if (data.name === "socialLogin") {
        localStorage.setItem("tokenEnterPersona", data.data.tokens.accessToken);
        setUserIsRegistration(true);
        setModalOpen(false);
      }
    });
  }, [setUserIsRegistration]);

  return (
    <>
      <Tooltip title="Личный кабинет">
        <div
          className={classNames(styles.enter_button)}
          onClick={() => {
            !userIsRegistration && setModalOpen(true);
          }}
        >
          {userIsRegistration ? (
            <Link
              to="/profile"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                border: "1px solid black",
                padding: "0.2rem",
                width: "2.0rem",
                height: "2rem",
              }}
            >
              <CiUser />
            </Link>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <CiUser />
              <span className={styles.registration_text}>
                Вход | Регистрация
              </span>
            </div>
          )}
        </div>
      </Tooltip>

      <Modal
        centered
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={false}
        width={"25rem"}
      >
        <div className={styles.modal}>
          <FormRegistration
            styles={styles}
            setUserIsRegistration={setUserIsRegistration}
          />
        </div>
      </Modal>
    </>
  );
};
