import { Avatar } from "antd";
import { RiDoubleQuotesL } from "react-icons/ri";

const data = {
  comment:
    "Чей то комментарий касаемо контента в посте, фраза на две или 3 строки с подписью автора",
  author: "Василий Петрович, глава СХО проекта",
  avatar: "/img/fon.png",
  title: "Баст",
  direction: "ECCOMMERCE",
  header: "Чиать этот класный кейс полностью",
  description:
    "Небольшое но емкое описание того что содержиться в посте, другими словами важная мысль и пользовапоста",
  background: "/img/fon.png",
  backgroundColor: "#9f2e70",
};

export const ContentCaseMain = ({ styles }) => {
  return (
    <div
      className={styles.content}
      style={{
        background: `linear-gradient(270deg, ${
          data.backgroundColor
        } 60%,  ${"black"} 100%)`,
      }}
    >
      <div className={styles.left}>
        <div className={styles.comment}>
          <RiDoubleQuotesL /> {data.comment}
        </div>
        <div className={styles.author}>- {data.author}</div>

        <div className={styles.direction}>{data.direction}</div>
        <div className={styles.header}>{data.header}</div>
        <div className={styles.description}>{data.description}</div>
      </div>

      <div className={styles.right}>
        <div className={styles.info}>
          <Avatar image={data.avatar} />
          <div>{data.title}</div>
        </div>

        <div className={styles.image} />
      </div>
    </div>
  );
};
