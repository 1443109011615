import { Button } from "antd";
import { MainForm } from "./MainForm";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useEffect, useState } from "react";
import { FormForgotPassword } from "./FormForgotPassword";

export const UserHaveAccount = ({
  setIsuserAccount,
  setUserIsRegistration,
}) => {
  const [userForgotPassword, setUserForgotPassword] = useState(false);

  const [apiEmail, setApiEmail] = useState("");
  const [apiPhone, setApiPhone] = useState("");

  useEffect(() => {
    if (userForgotPassword) {
      setApiEmail("https://persona.bz/api/auth/recover-by-email");
      setApiPhone("https://persona.bz/api/auth/recover-by-phone");
    } else {
      setApiEmail("https://persona.bz/api/auth/login-by-email");
      setApiPhone("https://persona.bz/api/auth/login-by-phone");
    }
  }, [userForgotPassword]);

  return (
    <>
      <h2 style={{ marginTop: "2rem" }}>
        {userForgotPassword ? "Восстановление пароля" : "Вход"}
      </h2>
      <Button
        style={{
          position: "absolute",
          padding: 0,
          display: "flex",
          alignItems: "center",
          top: "1rem",
          left: "1rem",
        }}
        onClick={() => setIsuserAccount(false)}
        type="text"
      >
        <MdKeyboardArrowLeft fontSize={"1.5rem"} />
      </Button>

      {userForgotPassword ? (
        <FormForgotPassword
          setUserIsRegistration={setUserIsRegistration}
          apiEmail={apiEmail}
          apiPhone={apiPhone}
        />
      ) : (
        <MainForm
          apiEmail={apiEmail}
          labelButton={"Войти"}
          apiPhone={apiPhone}
          setUserIsRegistration={setUserIsRegistration}
        />
      )}

      {!userForgotPassword && (
        <Button
          onClick={() => setUserForgotPassword(true)}
          style={{ width: "100%", marginTop: "1rem" }}
        >
          Забыли пароль?
        </Button>
      )}
    </>
  );
};
