import { IoIosTimer } from "react-icons/io";
import { Categories } from "../Categories/component";
import { MdOutlineMoreTime } from "react-icons/md";
import { PiHouseLine } from "react-icons/pi";
import { useState } from "react";
import { Slider } from "antd";

export const FormatWork = () => {
  const [selectItems, setSelectItems] = useState([]);

  const items = [
    {
      name: "Почасовая оплата",
      image: <IoIosTimer style={{ height: "45%", width: "45%" }} />,
      visible: true,
      libraryImage: true,
      price: [10, 999],
      price1: 10,
      price2: 999,
    },

    {
      name: "Проектная работа",
      image: <MdOutlineMoreTime style={{ height: "45%", width: "45%" }} />,
      visible: true,
      libraryImage: true,
      price: [300, 100000],
      price1: 30,
      price2: 100000,
    },

    {
      name: "In house",
      image: <PiHouseLine style={{ height: "45%", width: "45%" }} />,
      visible: true,
      libraryImage: true,
      price: [1000, 1000000],
      price1: 1000,
      price2: 1000000,
    },
  ];

//   const [inputValue, setInputValue] = useState(1);

  return (
    <div>
      <Categories
        items={items}
        onCheckedItem={(addItem) => {
          if (selectItems.includes(addItem)) return;

          setSelectItems([...selectItems, addItem]);
        }}
        onUnCheckedItem={(addItem) => {
          if (!selectItems.includes(addItem)) return;
          setSelectItems(
            selectItems?.filter((selectItem) => selectItem !== addItem)
          );
        }}
        setSelectItems={setSelectItems}
      />

      {selectItems?.map((name) => {
        return (
          <div style={{ marginBottom: "2rem" }}>
            <h4 style={{ marginBottom: "0rem" }}>{name}</h4>
            <div style={{  marginTop: "3.5rem" }}>
              {items.map((item) => {
                if (item.name === name) {
                  return (
                    <Slider
                      //   onChange={onChange}
                      onChangeComplete={(e) => console.log(e)}
                      tooltip={{
                        open: true,
                      }}
                      range
                      min={item.price1}
                      max={item.price2}
                      defaultValue={[item.price1, item.price2]}
                    />
                  );
                }
                return null
              })}
            </div>
          </div>
        );
      })}
      {/* {inputValue} */}
    </div>
  );
};
