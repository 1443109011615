import { MdErrorOutline } from "react-icons/md";

export const ErrorMessage = ({ children }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", color: "red" }}>
      <MdErrorOutline style={{ marginRight: "0.5rem" }} />
      <div>{children}</div>
    </div>
  );
};
