import { Select } from "antd";

const stages = [
  { label: "Аналитика", value: "Аналитика" },
  { label: "MVP", value: "MVP" },
  { label: "Рост", value: "Рост" },
  { label: "Обновление технологий", value: "Обновление технологий" },
];

export const OneSelect = ({ current }) => {
  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };
  
  return (
    <Select
      size={"large"}
      placeholder={current.title}
      defaultValue={stages[0]}
      onChange={handleChange}
      style={{
        width: "100%",
      }}
      options={stages}
    />
  );
};
