import styles from "./styles/styles.module.css";
import { SelectHeader } from "../SelectHeader/component";
import { LocationCountry } from "../LocationCountry/component";
import { AiFillBehanceCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { Menu } from "../Menu/component";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Registration } from "../Registration/component";
import { instance } from "../../axios";
import { Tooltip } from "antd";

export const Header = ({ children, showMiniHeader }) => {
  const selectsItems = [
    {
      key: 0,
      label: "Таланты",
      items: [
        {
          image: <AiFillBehanceCircle />,
          title: "1Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
        {
          image: <AiFillBehanceCircle />,
          title: "1Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
        {
          image: <AiFillBehanceCircle />,
          title: "1Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
        {
          image: <AiFillBehanceCircle />,
          title: "1Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
      ],
    },
    {
      key: 1,
      label: "Компании",
      items: [
        {
          image: <AiFillBehanceCircle />,
          title: "2Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
        {
          image: <AiFillBehanceCircle />,
          title: "2Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
        {
          image: <AiFillBehanceCircle />,
          title: "2Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
        {
          image: <AiFillBehanceCircle />,
          title: "2Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
      ],
    },

    {
      key: 2,
      label: "Кейсы студий",
      items: [
        {
          image: <AiFillBehanceCircle />,
          title: "Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
        {
          image: <AiFillBehanceCircle />,
          title: "Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
        {
          image: <AiFillBehanceCircle />,
          title: "Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
        {
          image: <AiFillBehanceCircle />,
          title: "Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
      ],
    },

    {
      key: 3,
      label: "О нас",
      items: [
        {
          image: <AiFillBehanceCircle />,
          title: "Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
        {
          image: <AiFillBehanceCircle />,
          title: "Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
        {
          image: <AiFillBehanceCircle />,
          title: "Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
        {
          image: <AiFillBehanceCircle />,
          title: "Lorem Title",
          text: "Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах",
        },
      ],
    },
  ];

  const [scrolled, setScrolled] = useState(false);

  const userIsEnter = localStorage.getItem("tokenEnterPersona");

  const [userIsRegistration, setUserIsRegistration] = useState(
    userIsEnter?.length > 0 && userIsEnter !== null ? true : false
  );

  useEffect(() => {
    const isRedirectRegistry =
      window.location.pathname.indexOf("email-activation") !== -1 ||
      window.location.pathname.indexOf("reset-password") !== -1;
    if (isRedirectRegistry) {
      const code = window.location.pathname.slice(-4);

      const getEmailFromUrl = (url) => {
        const parts = url.split("/");
        const emailPart = parts.find((part) => part.includes("@"));
        return emailPart;
      };

      const email = getEmailFromUrl(window.location.pathname);

      const userHaveToken = localStorage.getItem("tokenEnterPersona");
      debugger;
      if (userHaveToken === null || userHaveToken?.length === 0) {
        instance
          .get(`https://persona.bz/api/auth/activate-email/${email}/${code}`, {
            email: email,
            code: code,
          })
          .then((response) => {
            console.log("response", response);
            if (response.status === 200) {
              debugger;

              setUserIsRegistration(true);
              localStorage.setItem(
                "tokenEnterPersona",
                response.data.data.tokens.accessToken
              );

              window.location.replace("/");
            }
          })

          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  return (
    <div
      className={classNames(styles.header, scrolled && styles.header_scrolled)}
    >
      {children}
      <div className={styles.inner}>
        <div className={styles.content}>
          <Link to="/">
            <div className={styles.logo}>Persona</div>
          </Link>
          <SelectHeader
            styles={styles}
            showMiniHeader={showMiniHeader}
            selectsItems={selectsItems}
            scrolled={scrolled}
          />
        </div>

        <div className={styles.content}>
          <Tooltip title="Добавьте свои кейсы">
            <Link to={"/addcase"}>
              <div className={classNames(styles.content_item, styles.enter)}>
                <PlusCircleOutlined />
                <span className={styles.enter_text}>Добавить кейс</span>
              </div>
            </Link>
          </Tooltip>

          <Registration
            setUserIsRegistration={setUserIsRegistration}
            userIsRegistration={userIsRegistration}
          />

          <LocationCountry styles={styles} />
          <Menu
            selectsItems={selectsItems}
            setUserIsRegistration={setUserIsRegistration}
            userIsRegistration={userIsRegistration}
          />
        </div>
      </div>
    </div>
  );
};
