import { ItemLink } from "./ItemLink";
import { UploaderFiles } from "./UploaderFiles";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { FaCode } from "react-icons/fa";
import { IoPersonCircleOutline } from "react-icons/io5";
import { FaYoutube } from "react-icons/fa";
import { Button } from "antd";
import { IoMdAdd } from "react-icons/io";
import { useState } from "react";
import { MdDeleteOutline } from "react-icons/md";

const defaultLinks = [
  {
    label: "LinkedIn",
    type: "link",
    icon: <FaLinkedin />,
    isDefaultLink: true,
  },
  {
    label: "Facebook",
    type: "link",
    icon: <FaFacebook />,
    isDefaultLink: true,
  },
  {
    label: "Instagram",
    type: "link",
    icon: <FaInstagramSquare />,
    isDefaultLink: true,
  },
  {
    label: "Telegram",
    type: "link",
    icon: <FaTelegram />,
    isDefaultLink: true,
  },
  {
    label: "Личный сайт",
    type: "link",
    icon: <IoPersonCircleOutline />,
    isDefaultLink: true,
  },
  {
    label: "GitHub | GitLab",
    type: "link",
    icon: <FaCode />,
    isDefaultLink: true,
  },
  { label: "YouTube", type: "link", icon: <FaYoutube />, isDefaultLink: true },
];

export const ProfileLinks = () => {
  const [items, setItems] = useState(defaultLinks);

  const addItemLink = () => {
    setItems([...items, { label: "Название ресурса", type: "link" }]);
  };

  const deleteItem = (label) => {
    setItems(items.filter((item) => item.label !== label));
  };

  return (
    <div>
      {items.map((item) => {
        return (
          <ul>
            <li
              style={{
                display: "flex",
                width: "100%",
                alignItems: "flex-end",
                marginBottom: "1.5rem",
              }}
            >
              <ItemLink
                key={item.label}
                label={item.label}
                icon={item.icon}
                isDefaultLink={item.isDefaultLink}
              />

              <Button
                onClick={() => deleteItem(item.label)}
                shape="circle"
                style={{ marginLeft: "1rem" }}
                size="large"
              >
                <MdDeleteOutline />
              </Button>
            </li>
          </ul>
        );
      })}

      <Button
        size="large"
        style={{ marginBottom: "2rem", display: "flex", alignItems: "center" }}
        type="primary"
        onClick={() => addItemLink()}
      >
        <div>Добавить еще ссылку</div>
        <div style={{ marginTop: "3px", marginLeft: "1rem" }}>
          <IoMdAdd />
        </div>
      </Button>

      <UploaderFiles />
    </div>
  );
};
