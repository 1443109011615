import { Button, Input } from "antd";
import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";

export const ItemLink = ({ label, icon, isDefaultLink }) => {
  const [isEditLabel, setIsEditLabel] = useState(false);
  const [labelName, setLabelName] = useState(label);

  const onChange = (e) => {
    console.log(e);
  };

  const onChangeLabel = (e) => {
    setLabelName(e.target.value);
  };

  return (
    <div>
      <div style={{ marginBottom: "0.3rem", display: "flex" }}>
        {isEditLabel ? (
          <Input
            placeholder="Название ресурса"
            allowClear
            value={labelName}
            variant="borderless"
            prefix={icon}
            maxLength={20}
            size="small"
            onChange={onChangeLabel}
          />
        ) : (
          <>{labelName}</>
        )}
        {!isDefaultLink && (
          <Button
            onClick={() => {
              setIsEditLabel(!isEditLabel);
            }}
            shape="circle"
            type="text"
            style={{ marginLeft: "1rem" }}
            size="small"
          >
            {isEditLabel ? <FaRegSave /> : <MdEdit />}
          </Button>
        )}
      </div>

      <Input
        placeholder="Введите ссылку"
        allowClear
        prefix={icon}
        size="large"
        onChange={onChange}
      />
    </div>
  );
};
