export const steps = [
  {
    step: 1,
    typeStep: "profile",
    title: "Формат работы",
    info: "Какой формат работы, проектов и какой уровень оплаты тебе стоит показывать",
    status: "process",
    type: "format_work",
  },
  {
    step: 2,
    typeStep: "profile",
    title: "Ссылки",
    info: "Расскажи какие у тебя есть профили и сайты. Чем больше ты укажешь - тем больше это вызовет доверия у клиентов. Они не публикуются. Не волнуйся) ",
    status: "process",
    type: "lists",
  },

  {
    step: 3,
    typeStep: "profile",
    title: "Локация | Язык",
    info: "В каком ты городе? 98% работы удаленно. Но бывают интересные предложения на местах.  На каких языках ты готов делать проекты?",
    status: "process",
    type: "location",
  },

  {
    step: 4,
    typeStep: "profile",
    title: "Роли",
    info: "Какая у тебя роль в проектах? Укажи 1-3 основные. И  дополнительные",
    status: "process",
    type: "role_select",
  },

  {
    step: 5,
    typeStep: "profile",
    title: "Кейс",
    info: "Давай заполним кейсы. Persona будет подбирать тебе клиентов на основании этих кейсов. ",
    // status: "process",
    type: "next_step",
  },

  {
    step: 6,
    typeStep: "case",
    title: "Название кейса",
    placeholder: "Название кейса",
    status: "process",
    type: "input",
  },
  {
    step: 7,
    typeStep: "case",
    title: "Что делали?",
    status: "process",
    type: "text_aria",
  },
  {
    step: 8,
    typeStep: "case",
    title: "Стадия проекта",
    status: "process",
    type: "select",
  },
  {
    step: 9,
    typeStep: "case",
    title: "Задача / Проблема",
    status: "process",
    type: "text_aria",
  },
  {
    step: 10,
    typeStep: "case",
    title: "Решение /Процесс",
    status: "process",
    type: "text_aria",
  },
  {
    step: 11,
    typeStep: "case",
    title: "Результат /Ключевые метрики",
    status: "process",
    type: "text_aria",
  },
  {
    step: 12,
    typeStep: "case",
    title: "Отзыв. Прессрелиз",
    status: "process",
    type: "text_aria",
  },
  {
    step: 13,
    typeStep: "case",
    title: "Контент",
    type: "upload",
    info: "Фото/Видео кейса/Шортс, все, что сможет дополнительно описать вашу работу",
    status: "process",
  },
  {
    step: 14,
    typeStep: "case",
    title: "Дополнительные теги",
    type: "tags",
    info: "Вы можете написать самые разные теги, которые подробнее опишут Вас и Ваш кейс",
    status: "process",
  },
];
