import { CiLogout } from "react-icons/ci";
import { Layout } from "../Layout/component";
import { LoyoutItem } from "../LayoutItem/component";
import { Button } from "antd";
// import styles from "./styles/styles.module.css";

export const Profile = () => {
  return (
    <Layout>
      <LoyoutItem>
        <div style={{ marginTop: "200px" }}>
          Это страница профиля, тут нужен контент!
        </div>

        <Button
          onClick={() => {
            localStorage.setItem("tokenEnterPersona", "");
            window.location.replace("/");
          }}
          size="large"
          style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}
        >
          <CiLogout style={{ marginRight: "0.5rem" }} />
          Выйти
        </Button>
      </LoyoutItem>
    </Layout>
  );
};
